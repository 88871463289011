<template>
  <div class="formContent">
    <!-- <img class="imgIcon" src="../assets/img/ziliao.png" alt="" /> -->
    <img class="imgIcon" src="../assets/img2/formHeader.png" alt="" />
    <van-form @submit="onSubmit" class="formBox" label-width="120px">
      <van-field
        disabled
        :value="username"
        label="申请人"
        input-align="right"
        placeholder="请输入申请人姓名"
        :rules="[
          {
            required: true,
          },
        ]"
      />
      <van-field
        input-align="right"
        v-model="from.phone"
        label="手机号"
        type="number"
        placeholder="请输入申请人手机号"
        :rules="[
          {
            pattern:
              /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/,
          },
        ]"
      />
      <van-field
        input-align="right"
        style="align-items: center"
        readonly
        clickable
        name="city"
        :value="from.city"
        :rules="[{ required: true }]"
        label="所在城市"
        placeholder="请选择"
      >
        <template #button>
          <van-button
            style="
              background: linear-gradient(90deg, #f7d6ae, #e8b179);
              border: none;
            "
            @click.stop="areaShowPicker = true"
            size="small"
            type="primary"
            >点击选择城市</van-button
          >
        </template>
      </van-field>
      <!-- <van-field
        :value="from.carValue"
        readonly
        name="carValue"
        label="车牌号"
        placeholder="请输入"
        :rules="[{ pattern, message: '请填写正确的车牌号' }]"
        @click="show = true"
      /> -->
      <van-field name="carValue" label="车牌号" label-width="160px">
        <template #input>
          <div class="car_value">
            <div class="car_value_left" @click="showCarPopup = true">
              <div class="car_value_left_txt">{{ car_txt }}</div>
            </div>
            <div class="car_value_right">
              <van-field
                style="padding: 0"
                v-model="from.carValue"
                input-align="right"
                :rules="[{ required: true }]"
                placeholder="请输入车牌号"
              />
            </div>
          </div>
        </template>
      </van-field>
      <van-field
        readonly
        clickable
        name="type"
        input-align="right"
        :value="from.type"
        :rules="[{ required: true }]"
        label="购车方式"
        placeholder="请选择购车方式"
        @click.stop="showPicker = true"
      />
      <!-- <van-field label-width="100" name="type" label="车辆是否有贷款">
        <template #input>
          <van-radio-group
            icon-size="15px"
            v-model="from.type"
            direction="horizontal"
          >
            <van-radio name="有" checked-color="#333">有</van-radio>
            <van-radio name="没有" checked-color="#333">没有</van-radio>
          </van-radio-group>
        </template>
      </van-field> -->
      <!-- <van-field
        v-model="from.smsCode"
        center
        name="smsCode"
        clearable
        label="验证码"
        placeholder="请输入"
        :rules="[{ required: true, message: '请输入短信验证码' }]"
      >
        <template #button>
          <van-button
            size="small"
            type="primary"
            @click.prevent="sendSmsCode"
            :disabled="isSmsSend"
            color="linear-gradient(to right, #fad9a2, #d3a17b)"
            >{{ sendBtnText }}</van-button
          >
        </template>
      </van-field> -->

      <van-field name="checkbox" :border="false">
        <template #input>
          <van-checkbox
            icon-size="16px"
            v-model="checkbox"
            checked-color="#d3a17b"
            >我已阅读并同意
            <span @click.stop="openText" class="openText"
              >《留资隐私政策》</span
            ></van-checkbox
          >
        </template>
      </van-field>

      <vnp-keyboard @input="handleCarValue" :show.sync="show"></vnp-keyboard>
      <van-popup round v-model="showPicker" position="bottom">
        <van-picker
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
      <van-popup round v-model="areaShowPicker" position="bottom">
        <van-area
          @confirm="areaConfirm"
          @cancel="areaShowPicker = false"
          title="所在城市"
          :columns-num="2"
          :area-list="areaList"
          :columns-placeholder="['请选择', '请选择']"
        />
      </van-popup>
      <div>
        <van-button
          round
          block
          native-type="submit"
          color="linear-gradient(to bottom, #f7d6ae, #e8b179)"
          >提 交</van-button
        >
      </div>
    </van-form>
    <van-image :src="footerImg" style="margin-top: 28px" />
    <van-popup
      safe-area-inset-bottom
      v-model="showCarPopup"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <div class="car_popup">
        <div class="car_popup_box">
          <div class="car_popup_cont">
            <div
              class="car_popup_row"
              v-for="(item, index) in carList"
              :key="index"
            >
              <div
                @click.stop="handleCarSelect(sitem)"
                class="car_popup_col"
                :class="{ car_select: isCarSelect(sitem) }"
                v-for="(sitem, index) in item"
                :key="index"
              >
                {{ sitem }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup
      v-model="showPopup"
      round
      closeable
      position="bottom"
      class="popupContent"
      :style="{ height: '80%' }"
    >
      <div class="textContent">
        <p class="title">隐私政策</p>
        <p class="textOne" style="text-indent: 2em">
          尊敬的客人，感谢您选择我司为您提供服务！
        </p>
        <p style="text-indent: 2em">
          海通融资租赁(辽宁)有限公司及其关联实体（以下称“我们”）依据有关法律请您作如下授权，请您点击同意之前仔细阅读并充分理解相关条款，其中的重点条款已为您标注，方便您了解自己的权利和授权内容。
        </p>
        <p>
          1、为了您更好的享受网站服务及申请金融服务，我们会根据您的授权内容，收集、使用您提供的必要信息，例如：您的个人基础信息、学历信息、婚姻状况、工作信息、选择的融资车辆、选择的服务产品、融资期限、个人信用信息、个人资产信息等；
        </p>
        <p>
          2、为了给您提供服务，我们仅在法律法规要求的期限内，以及为实现您对我们服务与/或产品使用之目的所必须的时限内保留您的个人信息。
        </p>
        <p>
          3、为了向您提供融资信息服务，将通过您预留信息给您推荐（包括向您发送融资产品介绍短信、邮件、拨打电话等营销方式）银行或其他金融机构及融资产品。
        </p>
        <p>
          4、我们不会与我们以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
        </p>
        <p>（1）事先获得您明确的同意或授权。</p>
        <p>
          （2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下对外共享。
        </p>
        <p>
          （3）只有共享您的信息，才能实现我们的服务与/或产品的核心功能或提供您需要的服务。
        </p>
        <p>
          （4）与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供。
        </p>
        <p>
          （5）与我们的关联方共享：我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。
        </p>
        <p>
          （6）我们会对合作方获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与合作方约定严格的数据保护措施，令其按照我们的委托目的、服务条款、本隐私协议以及其他任何相关的保密和安全措施来处理个人信息。
        </p>
        <p>
          5、 我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </p>
        <p>（1）事先获得您明确的同意或授权。</p>
        <p>
          （2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行转让。
        </p>
        <p>
          （3）根据法律法规或商业惯例，在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则，我们将要求该公司、组织重新向您征求授权同意。
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
import Keyboard from "@/components/vant-number-plate/vnp-keyboard.vue";
import { getSearchParams } from "@/utils";
import footerImg from "../assets/img2/footer.png";

const BASE_API_URL = "https://caradmin.huilikj.cn";
export default {
  name: "FormContent",
  props: {},
  components: {
    "vnp-keyboard": Keyboard,
  },
  data() {
    return {
      carList: [
        ["京", "津", "沪", "渝", "冀", "豫"],
        ["云", "辽", "黑", "湘", "皖", "鲁"],
        ["新", "苏", "浙", "赣", "鄂", "桂"],
        ["甘", "晋", "蒙", "陕", "吉", "闽"],
        ["贵", "粤", "青", "藏", "川", "宁"],
        ["琼"],
      ],
      footerImg,
      areaList,
      isSmsSend: false,
      car_txt: "京",
      pattern:
        /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[DF])|([DF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/,
      from: {
        channelCode: "",
        username: "",
        phone: "",
        smsCode: "",
        carValue: "",
        type: "",
        city: "",
        certNo: "",
      },
      username: "",
      phone: "",
      sendBtnText: "点击发送验证码",
      timer: null,
      counter: 60,
      show: false,
      showCountdown: false, //是否显示倒计时
      columns: ["全款", "分期已结清", "分期未结清"],
      showPicker: false,
      areaShowPicker: false,
      checkbox: false,
      showPopup: false,
      isSubmit: false,
      showCarPopup: false,
    };
  },
  computed: {
    isCarSelect() {
      return function (item) {
        return item === this.car_txt;
      };
    },
  },
  async mounted() {
    /**
     * userId
     * certNo
     * userName
     * userMobile
     */
    const { userId, channelCode } = getSearchParams();
    this.from.userId = userId;
    this.from.channelCode = channelCode;
    await this.setPvUv();
    await this.getUserInfo();
    const { data } = await this.getUserIp();
    if (data) {
      const res = await this.analysisIp(data);
      if (res) {
        this.from.city = res.city;
        await this.setCity();
      }
    }
  },
  methods: {
    handleCarSelect(v) {
      this.car_txt = v;
      this.showCarPopup = false;
    },
    /**
     * 获取设备类型
     */
    getDevice() {
      let agent = navigator.userAgent.toLowerCase();
      let result = {
        device: (function () {
          if (/windows/.test(agent)) {
            return "windows pc";
          } else if (/iphone|ipod/.test(agent) && /mobile/.test(agent)) {
            return "iphone";
          } else if (/ipad/.test(agent) && /mobile/.test(agent)) {
            return "ipad";
          } else if (/android/.test(agent) && /mobile/.test(agent)) {
            return "android";
          } else if (/linux/.test(agent)) {
            return "linux pc";
          } else if (/mac/.test(agent)) {
            return "mac";
          } else {
            return "other";
          }
        })(),
      };
      return result;
    },
    /**
     * Pv,uv
     */
    async setPvUv() {
      this.$axios
        .get(
          `${BASE_API_URL}/api/Users/viewRecord?url=${this.from.channelCode}`
        )
        .then((res) => {
          console.log(res);
        });
    },
    /**
     * 获取IP地址
     */
    async getUserIp() {
      return this.$axios.get(
        `${BASE_API_URL}/index.php/api/users/get_client_ip`
      );
    },
    async setCity() {
      this.$axios.get(
        `${BASE_API_URL}/api/Users/jd_user_city?userId=${this.from.userId}&city=${this.from.city}`
      );
    },
    /**
     * 解析Ip地址地理位置
     */
    async analysisIp(ip) {
      return new Promise((resolve) => {
        this.$axios
          .get(`https://ips.market.alicloudapi.com/iplocaltion?ip=${ip}`, {
            headers: {
              Authorization: "APPCODE 71696684901d4989a34b165653c7b0d0",
            },
          })
          .then(({ data }) => {
            resolve(data.result);
          });
      });
    },
    /**
     * 重置倒计时
     */
    reset() {
      this.isSmsSend = false;
      this.sendBtnText = "点击发送验证码";
      if (this.timer) {
        clearInterval(this.timer);
        this.counter = 60;
        this.timer = null;
      }
    },
    /**
     * 倒计时
     */
    countDown() {
      this.timer = setInterval(() => {
        this.sendBtnText = `${this.counter}秒`;
        this.counter--;
        if (this.counter < 0) {
          this.reset();
        }
      }, 1000);
    },
    // 当手机号变化时，重置发送按钮
    onPhoneChange() {
      this.reset();
    },
    /**
     * 发送验证码
     */
    sendSmsCode() {
      // 判断手机号是否已经输入
      if (!this.from.phone) {
        this.$notify("请输入手机号");
        return false;
      }
      // 判断手机号是否符合要求
      if (this.from.phone.length !== 11) {
        this.$notify("请输入11位手机号");
        return false;
      }
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      // 调用接口，发送短信验证码
      this.isSmsSend = true;
      this.$axios
        .get(
          `${BASE_API_URL}/index.php/api/users/sendAppSms?mobile=${this.from.phone}&event=userVerify`
        )
        .then(({ data }) => {
          toast.clear();
          if (data && data.code !== 1) {
            this.$notify(data.msg);
          }
        })
        .catch(() => {
          this.$notify("服务出错了");
          toast.clear();
        });
      // 开始倒计时，60s之后才能再次点击
      this.countDown();
    },
    async getUserInfo() {
      // const data = {
      //   data: {
      //     userName: "张样",
      //     userMobile: "18615770991",
      //     certNo: "513001199312130833",
      //   },
      // };
      // this.username = this.desensitizedName(data.data.userName);
      // this.phone = this.desensitizedPhone(data.data.userMobile);
      // this.from.username = data.data.userName;
      // this.from.phone = data.data.userMobile;
      // this.from.certNo = data.data.certNo;
      // return;
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });

      this.$axios
        .get(
          `${BASE_API_URL}/index.php/api/users/getJDUser?userId=${this.from.userId}`
        )
        .then(({ data }) => {
          toast.clear();
          if (data && data.dealStatus !== "0000") {
            this.$notify("出错了");
            return;
          }
          this.username = this.desensitizedName(data.data.userName);
          this.from.username = data.data.userName;
          this.from.phone = data.data.userMobile;
          this.from.certNo = data.data.certNo;
        })
        .catch(() => {
          this.$notify("服务出错了");
          toast.clear();
        });
    },
    onSubmit(values) {
      if (this.isSubmit) {
        this.$toast("您的信息已提交成功，请勿重复提交");
        return;
      }
      if (!values.checkbox) {
        this.$toast("请勾选隐私政策");
        return;
      }
      let newCarValue = this.car_txt + this.from.carValue.toUpperCase();
      if (!this.pattern.test(newCarValue)) {
        this.$toast("请输入正确的车牌号");
        return;
      }
      if (!this.from.username) {
        this.$toast("无申请人");
        return;
      }
      if (!this.from.phone) {
        this.$toast("无手机号");
        return;
      }
      const toast = this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      const params = {
        channel: this.from.channelCode,
        userId: this.from.userId,
        custName: this.from.username,
        phoneNo: this.from.phone,
        city: this.from.city,
        type: this.from.type,
        car_value: newCarValue,
        idCard: this.from.certNo,
      };

      this.$axios({
        method: "POST",
        url: `${BASE_API_URL}/index.php/api/users/xy_userts`,
        data: params,
      })
        .then(({ data }) => {
          toast.clear();
          if (data && data.code !== 1) {
            this.$notify(data.msg);
            return;
          }
          this.isSubmit = true;
          this.$dialog.alert({
            title: "您的信息已提交成功",
            message: "海通融资客服将会与您联系，请您保持手机畅通！",
            confirmButtonText: "我知道了",
            theme: "round-button",
            confirmButtonColor: "#d3a17b",
          });
        })
        .catch(() => {
          this.$notify("服务出错了");
          toast.clear();
        });
    },
    areaConfirm(value) {
      if (!value[1]) return;
      if (!value[1].code) return;
      let name = value[1].name;
      if (value[1].code === "500200") {
        name = "重庆郊县";
      }
      this.from.city = name;
      console.log(this.from.city);
      this.areaShowPicker = false;
    },
    onConfirm(value) {
      this.from.type = value;
      this.showPicker = false;
    },
    openText() {
      this.showPopup = true;
    },
    handleCarValue(val) {
      this.from.carValue = val;
    },
    desensitizedName(fullName) {
      if (!fullName) {
        return "";
      }

      let str = fullName.substr(0, 1);
      for (let i = 0; i < fullName.length - 1; i++) {
        str += "*";
      }

      return str;
    },
    /**
     * 手机号脱敏
     * @param str
     * @returns {string|*|string}
     */
    desensitizedPhone(str) {
      if (!str) {
        return "";
      }
      return str.replace(
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{4}(\d{4})$/,
        "$1****$2"
      );
    },
  },
};
</script>

<style lang="less">
.formContent {
  margin-top: 560px;
  position: relative;
  .van-field__label {
    color: #333;
    font-weight: bold;
  }
}
.car_select {
  background-color: #f38831;
  color: #fff;
}

.car_popup {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .car_popup_box {
    display: flex;
    width: 90%;
    height: 90%;
    .car_popup_cont {
      width: 100%;
      .car_popup_row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 40px;
        .car_popup_col {
          width: 80px;
          height: 80px;
          line-height: 80px;
          border: 1px solid rgb(230, 228, 228);
        }
      }
    }
  }
  // width: calc(100% - 20px);
  // height: calc(100% - 20px);
  // padding: 20px;
}
.car_value {
  width: 100%;
  display: flex;
  .car_value_left {
    .car_value_left_txt {
      width: max-content;
      color: #f38831;
      font-size: 28px;
      font-weight: bold;
    }
    .car_value_left_txt:after {
      content: "\25bc";
      padding-left: 6px;
      color: #f38831;
      font-size: 20px !important;
    }
  }
}
.imgIcon {
  width: 335px;
  height: 65px;
  position: absolute;
  z-index: 100;
  margin: auto;
  left: 0;
  right: 0;
  top: -16px;
}
.formBox {
  margin: 0px 30px;
  background: #ffffff;
  box-shadow: 0px 10px 28px 0px rgba(121, 125, 138, 0.2);
  border-radius: 12px;
  padding: 100px 20px 40px 20px;
}
.fontColor {
  color: black;
}
.openText {
  color: #d3a17b;
}
.textContent {
  padding: 0 20px 20px 20px;
  text-align: left;
  p {
    margin: 0;
  }
  .title {
    font-size: 40px;
    font-weight: bold;
    margin: 20px 0;
    text-align: center;
  }
  .textOne {
    font-size: 30px;
    font-weight: bold;
    margin: 10px 0;
  }
}
</style>
