<template>
  <div id="app">
    <!-- <div class="title">
      <img src="./assets/img/title.png" alt="" />
    </div> -->
    <FormContent />
    <!-- <div class="stepContent">
      <img class="imgIcon" src="../src/assets/img/step.png" alt="" />
      <div class="stepNumContent">
        <img class="stpeNum" src="../src/assets/img/stepNum.png" alt="" />
        <div class="textBox">
          <span>线上申请</span>
          <span>客服联系</span>
          <span>门店受理</span>
          <span>钱款到账</span>
        </div>
      </div>
    </div>
    <div class="conditionContent">
      <div>
        <img class="conditionImg" src="./assets/img/condition.png" alt="" />
      </div>
      <div class="conditionBox">
        <div class="conditionTextBox">
          <img
            class="conditionIcon"
            src="./assets/img/conditionOne.png"
            alt=""
          />
          <p>18-60周岁，中国大陆公民</p>
        </div>
        <div class="conditionTextBox">
          <img
            class="conditionIcon"
            src="./assets/img/conditionTwo.png"
            alt=""
          />
          <p>个人名下有车，车辆无异常</p>
        </div>
        <div class="conditionTextBox">
          <img
            class="conditionIcon"
            src="./assets/img/conditionThree.png"
            alt=""
          />
          <p>车龄不超过8年（以出厂日期为准）</p>
        </div>
      </div>
    </div>
    <div class="conditionContent">
      <div>
        <img
          class="conditionImg"
          src="./assets/img/characteristic.png"
          alt=""
        />
      </div>
      <div class="characteristicBox">
        <div class="characteristicText">
          <img
            class="characteristicIcon"
            src="./assets/img/characteristicOne.png"
            alt=""
          />
          <p>最高可贷80万</p>
        </div>
        <div class="characteristicText">
          <img
            class="characteristicIcon"
            src="./assets/img/characteristicTwo.png"
            alt=""
          />
          <p>期限灵活，12期、24期、36期、48期</p>
        </div>
        <div class="characteristicText">
          <img
            class="characteristicIcon"
            src="./assets/img/characteristicThree.png"
            alt=""
          />
          <p>流程简单，办理快速，最快当天到账</p>
        </div>
      </div>
    </div>
    <div class="bottomText">
      <p>
        海通融资租赁（辽宁）有限公司与合作伙伴共同推出融资服务，可提供产品的综合融资成本为8%~24%。
      </p>
      <p>
        根据您的融资需求，我们将一对一的为您确认服务伙伴和融资产品，届时您可以确定是否接受我们的服务。
      </p>
      <p>
        放款时间，审批额度均以实际审批为准，请根据您的偿还能力合理申请贷款。
      </p>
    </div> -->
  </div>
</template>

<script>
import FormContent from "./components/form.vue";

export default {
  name: "App",
  components: {
    FormContent,
  },
};
</script>

<style lang="less" scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // background: url("/src/assets/img/background.png");
  background: url("/src/assets/img2/header.png") no-repeat;
  background-size: 100%;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.title {
  position: absolute;
  top: 30px;
  left: 20px;
  img {
    width: 159px;
    height: 27px;
  }
}
.stepContent {
  position: relative;
  margin: 0px 50px;
  margin-top: 130px;
}
.stepNumContent {
  /* margin-top: 100px; */
  border-radius: 20px;
  padding: 60px 0 40px 0;
  background-color: #fffffd;
}
.imgIcon {
  width: 500px;
  height: 130px;
  position: absolute;
  z-index: 100;
  left: 78px;
  top: -56px;
}
.stpeNum {
  width: 561px;
  height: 78px;
  /* background-size: 100%; */
}
.textBox {
  display: flex;
  margin-top: 10px;
  justify-content: space-around;
  font-size: 30px;
}
.conditionContent {
}
.conditionImg {
  /* background-size: 100%; */
  width: 749px;
  height: 136px;
  margin: 40px 0 20px 0;
}
.conditionBox {
  border-radius: 20px;
  margin: 0px 50px;
  padding-top: 20px;
  background-color: #fffffd;
  .conditionTextBox {
    position: relative;
  }
  p {
    position: absolute;
    top: 16px;
    left: 180px;
    font-size: 26px;
  }
}
.characteristicBox {
  border-radius: 20px;
  margin: 0px 50px;
  padding: 20px 0;
  background-color: #fffffd;
}
.conditionIcon {
  width: 628px;
  height: 138px;
}
.characteristicIcon {
  width: 86px;
  height: 86px;
}
.characteristicText {
  display: flex;
  border-bottom: 1px solid #e6e1e1;
  align-items: center;
  font-size: 26px;
  margin: 0px 20px;
}
.bottomText {
  font-size: 28px;
  color: #f1efef;
  // letter-spacing: 50px;
  text-align: left;
  margin: 70px 50px 0 50px;
  p {
    margin: 0;
  }
}
</style>
